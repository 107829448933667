<template lang="pug">
  span(:title="$t(codename + '.help')") {{ $t(codename + '.name') }}
</template>

<script>
export default {
  props: {
    codename: {
      type: String,
      default: ''
    }
  }
}
</script>

<i18n>
{
  "es": {
    "emgAbsolute": {
      "name": "Electromiografía absoluta",
      "help": "Muestra un gráfico en línea de tiempo con los valores en absoluto."
    },
    "emgPercentage": {
      "name": "Electromiografia en porcentajes",
      "help": "Muestra un gráfico en línea de tiempo en relación al valor máximo de la serie."
    },
    "mdBars": {
      "name": "Distribución muscular en porcentajes",
      "help": "Muestra el nivel de \"esfuerzo\" que han recogido cada uno de los sensores y los compara en un gráfico de barras."
    },
    "mdRadar": {
      "name": "Radar de distribución muscular",
      "help": "Muestra en un gráfico de radar que músculo ha realizado más \"esfuerzo\"."
    },
    "mdSymmetry": {
      "name": "Índice de simetría muscular",
      "help": "Muestra en un gráfico circular qué músculo ha realizado más esfuerzo. Especialmente indicado para 2 sensores."
    }
  }
}
</i18n>
