<style lang="scss">
.clickable {
  cursor: pointer;
}
</style>

<template lang="pug">
  .card.card-solid
    .card-body.p-0
      .row
        .col-12
          table.table.table-hover.table-sm.m-0
            thead
              tr
                th {{ $t('table.header.name') }}
                th {{ $t('table.header.description') }}
                th {{ $t('table.header.duration') }}
                th {{ $t('table.header.state') }}
                th {{ $t('table.header.createdAt') }}
                th

            tbody(v-if="loading")
              td.text-center(colspan="6")
                .p-5
                  spinner(style="width: 3rem; height: 3rem;")

            tbody(v-else)
              tr(v-for="session in sessions" :class="{ 'clickable': isReady(session), 'text-muted': session.$removing }")
                td.align-middle {{ session.name }}
                td.align-middle
                  em(v-if="session.description") {{ session.description }}
                  em(v-else) ({{ $t('table.body.blankDescription') }})

                td.align-middle
                  duration(v-if="session.lastReport" :in-seconds="session.lastReport.duration")
                  span(v-else) -

                td.align-middle
                  session-state(:session="session" @refesh="refresh(session)")

                td.align-middle {{ session.createdAt | datetime }}
                td.align-middle
                  list-operations(
                    :show-remove="isReady(session)"
                    :enabled="!session.$removing"
                    @open="open(session)"
                    @remove="remove(session)"
                  )
                    button.ml-1(
                      type="button"
                      class="btn btn-primary btn-sm"
                      v-if="session.lastReport"
                      @click="report(session.lastReport)"
                    )
                      fa(icon="file-contract")
</template>

<script>
import _ from 'lodash';
import Duration from '@/components/common/Duration.vue'
import ListOperations from "@/components/common/ListOperations";
import http from '@/http/client';
import SessionState from "@/components/sessions/SessionState";

export default {
  components: {
    SessionState,
    Duration,
    ListOperations
  },

  data() {
    return {
      loading: false,
      sessions: []
    }
  },

  methods: {
    load() {
      this.loading = true;
      return http
        .get('/sessions')
        .then((response) => this.sessions = response.data)
        .finally(() => this.loading = false)
        ;
    },

    add() {
      this.$router.push({ name: 'sessions_create' })
    },

    open(session) {
      this.$router.push({ name: 'sessions_edit', params: { session: session.uuid }});
    },

    report(report) {
      this.$router.push({ name: 'report_view', params: { uuid: report.uuid }});
    },

    remove(session) {
      this.$swal
        .fire({
          title: this.$t('alert.remove.title', { name: session.name }),
          text: this.$t('alert.remove.warning'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('alert.remove.delete')
        })
        .then((result) => {
          if (!result.isConfirmed) {
              return;
          }

          session.$removing = true;
          http.delete(`/sessions/${session.uuid}`)
            .then(() => {
              _.remove(this.sessions, session);

              this.$swal.fire(
                this.$t('alert.removeSuccess.title'),
                this.$t('alert.removeSuccess.warning', { name: session.name }),
                'success'
              );

              this.$forceUpdate();
            })
          ;

          this.$forceUpdate();
      });
    },

    isReady(session) {
      return 'ready' === session.state;
    },

  },

  mounted() {
    this.load();
  }
}
</script>

<i18n>
{
  "es": {
    "alert": {
      "remove": {
        "title": "¿Desea eliminar la sesión \"{name}\"?",
        "warning": "Esta acción es irreversible",
        "delete": "Eliminar"
      },
      "removeSuccess": {
        "title": "Sesión eliminada",
        "warning": "La sesión \"{name}\" se eliminó correctamente"
      }
    },

    "table": {
      "header": {
        "name": "Nombre",
        "description": "Descripción",
        "duration": "Duración",
        "state": "Estado",
        "createdAt": "Fecha creación"
      },
      "body": {
        "blankDescription": "Sin descripción"
      }
    }
  }
}
</i18n>
