<template lang="pug">
  div
    spinner(v-if="'pending' === session.state" color="#CCD847" :title="$t('waiting')")
    spinner(v-if="'processing' === session.state" color="#3999CF" :title="$t('processing')")
    fa.text-success(icon="check")(v-if="'ready' === session.state")
</template>

<script>
import http from '@/http/client';

export default {
  props: {
    session: {
      type: Object,
      default:() => {}
    }
  },

  data() {
    return {
      abort: null
    }
  },

  mounted() {
    if('ready' === this.session.state) {
      return;
    }

    this.abort = setInterval(() => {
      if('ready' === this.session.state) {
        clearInterval(this.abort);
        return;
      }

      http.get(`/sessions/${this.session.uuid}/state`).then((response) => this.session.state = response.data.state);
    }, 5000)
  },

  destroyed() {
    if(null === this.abort) {
      return;
    }

    clearInterval(this.abort);
  }
}
</script>

<i18n>
{
  "es": {
    "waiting": "En cola",
    "processing": "Analizando sesión",
    "ready": "Sesión procesada"
  }
}
</i18n>
