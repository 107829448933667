<template lang="pug">
  form(@submit.prevent="onSubmit")
    .row
      .col-md-6
        .card
          h5.card-header {{ $t('form.activity.title') }}:

          .card-body
            .form-row
              .col-12
                .form-group
                  form-input(
                    id="name"
                    :model="$v.name"
                    :label="$t('form.activity.name.label')"
                    :placeholder="$t('form.activity.name.placeholder')"
                  )

            .form-row
              .col-6
                form-select-activity(
                  id="activity"
                  :model="$v.activity"
                  :label="$t('form.activity.type.label')"
                  :placeholder="$t('form.activity.type.placeholder')"
                )

              .col-6
                form-select-intensity(
                  id="intensity"
                  :model="$v.intensity"
                  :label="$t('form.activity.intensity.label')"
                  :placeholder="$t('form.activity.intensity.placeholder')"
                )

            .form-row
              .col-12
                form-text-area(
                  id="description"
                  :model="$v.description"
                  :label="$t('form.activity.description.label')"
                  :placeholder="$t('form.activity.description.placeholder')"
                )

      .col-md-6
        .card
          h5.card-header {{ $t('form.subject.title') }}:
          .card-body
            .form-row
              form-input-number(
                id="age"
                class="col-md-4"
                :model="$v.age"
                :label="$t('form.subject.age.label')"
                :placeholder="$t('form.subject.age.placeholder')"
              )

              form-input-group(
                id="height"
                type="number"
                class="col-md-4"
                append-text="cm"
                :model="$v.height"
                :label="$t('form.subject.height.label')"
                :placeholder="$t('form.subject.height.placeholder')"
              )

              form-input-group(
                id="weight"
                type="number"
                class="col-md-4"
                append-text="kg"
                :model="$v.weight"
                :label="$t('form.subject.weight.label')"
                :placeholder="$t('form.subject.weight.placeholder')"
              )

            .form-row
              form-select-gender(
                id="gender"
                class="col-md-4"
                :model="$v.gender"
                :label="$t('form.subject.gender.label')"
                :placeholder="$t('form.subject.gender.placeholder')"
              )

              form-text-area(
                id="observations"
                class="col-md-8"
                :model="$v.observations"
                :label="$t('form.subject.observations.label')"
                :placeholder="$t('form.subject.observations.placeholder')"
              )

    .row
      .col-md-12
        .card
          h5.card-header {{ $t('form.garment.title') }}:

          .card-body
            .row
              .col-md-4(v-if="!session")
                form-select-garment(
                  id="garment"
                  v-if="!session"
                  :model="$v.garment"
                  :label="$t('form.garment.garment.label')"
                  :placeholder="$t('form.garment.garment.placeholder')"
                )

              .pt-4(v-if="!$v.garment.$model" :class="{ 'col-md-8': !session, 'col-md-12': session }")
                .card.mb-3
                  .row.no-glutters.align-items-center
                    .col-md-8
                      ul.list-group.list-group-flush
                        li.list-group-item
                          h5 {{ $t('form.garment.details.selectOne') }}

              .pt-4(v-if="$v.garment.$model" :class="{ 'col-md-8': !session, 'col-md-12': session }")
                .alert.alert-warning(v-if="session")
                  fa(icon="exclamation-triangle")
                  = ' '
                  | {{ $t('edit.warning') }}

                .card.mb-3
                  .row.no-glutters.align-items-center
                    .col-md-8
                      ul.list-group.list-group-flush
                        li.list-group-item
                          h5 {{ $v.garment.$model.name }}
                          p.card-text(v-if="$v.garment.$model.description") {{ $v.garment.$model.description }}

                        li.list-group-item
                          h5 {{ $t('form.garment.details.sensors') }}:
                          div(v-for="sensor in $v.garment.$model.sensors")
                            fa(icon="microchip")
                            = ' '
                            span {{ sensor.uid }}:
                            = ' '
                            b {{ sensor.name }}

                        li.list-group-item
                          h5 {{ $t('form.garment.details.charts') }}:
                          div(v-for="(chart, codename) in $v.garment.$model.charts" v-if="chart.enabled")
                            fa(icon="chart-pie")
                            = ' '
                            chart-name(:codename="codename")

                    .col-md-4.text-center.mt-5.mb-5(v-if="!$v.garment.$model.hasImage")
                      fa.text-muted(icon="tshirt" size="6x")
                      div
                        span {{ $t('form.garment.details.noImage') }}

                    .col-md-4(v-else)
                      image-bucket.card-img(bucket="garment" :uuid="$v.garment.$model.uuid")

    .row(v-if="!session")
      .col-md-12
        .card
          h5.card-header {{ $t('form.file.title') }}

          .card-body
            .row
              .col-md-4
                form-input-file(
                  id="file"
                  :model="$v.file.file"
                  :search-text="$t('form.file.search')"
                  :label="$t('form.file.label')"
                )

                form-select-file-version(
                  :model="$v.file.version"
                  :label="$t('form.fileVersion.label')"
                  :placeholder="$t('form.fileVersion.placeholder')"
                  :help="$t('form.fileVersion.help')"
                )

              .col-md-8
                .card
                  .card-header
                    ul.nav.nav-tabs.card-header-tabs
                      li.nav-item
                        a.nav-link.active
                          fa(icon="wave-square")
                          = ' '
                          | {{ $t('outliers.tab.name') }}

                  .card-body
                    form-input-checkbox(
                      id="outliers-enabled"
                      :label="$t('outliers.enable')"
                      :model="$v.filters.outliers.enabled"
                      :enabled="false"
                    )

                    p.card-text.mt-3
                      span(v-html="$t('outliers.tab.description')")
                      = ' '
                      a(href="#" @click.prevent="toggleOutliersAdvancedConfig()")
                        span(v-if="filters.outliers.advanced") {{ $t('outliers.less') }}
                        span(v-else) {{ $t('outliers.more') }}

                      .row(v-if="filters.outliers.advanced")
                        form-input-number(
                          id="outliers-coefficient"
                          class="col-md-6"
                          :label="$t('form.coefficient.label')"
                          :model="$v.filters.outliers.coefficient"
                        )

                        form-input-group(
                          id="outliers-tolerance"
                          type="number"
                          class="col-md-6"
                          append-text="%"
                          :label="$t('form.tolerance.label')"
                          :model="$v.filters.outliers.tolerance"
                          :help="$t('form.tolerance.help')"
                        )

    .row
      .col-md-12
        button(type="submit" class="btn btn-lg btn-primary float-right" :disabled="submitting")
          span(v-if="!submitting")
            span(v-if="!session") {{ $t('form.submit.create.label') }}
            span(v-if="session") {{ $t('form.submit.edit.label') }}
            = ' '
            fa(icon="angle-right")

          span(v-if="submitting")
            button-spinner(color="#FFFFFF")
</template>

<script>
import { required, between, minLength, maxLength } from 'vuelidate/lib/validators'
import Duration from '@/components/common/Duration.vue'
import FormInput from '@/components/common/FormInput.vue'
import FormInputFile from '@/components/common/FormInputFile.vue'
import FormInputNumber from '@/components/common/FormInputNumber.vue'
import FormInputGroup from '@/components/common/FormInputGroup.vue'
import FormSelectGender from '@/components/common/FormSelectGender.vue'
import FormSelectIntensity from '@/components/common/FormSelectIntensity.vue'
import FormSelectActivity from '@/components/common/FormSelectActivity.vue'
import FormSelectGarment from '@/components/common/FormSelectGarment.vue'
import FormTextArea from '@/components/common/FormTextArea.vue'
import ListOperations from "@/components/common/ListOperations";
import http from '@/http/client';
import FormSelectFileVersion from "@/components/common/FormSelectFileVersion";
import FormInputCheckbox from "@/components/common/FormInputCheckbox";
import ImageBucket from "@/components/common/ImageBucket";
import ChartName from "@/components/charts/ChartName";
import _ from "lodash";

export default {
  components: {
    ImageBucket,
    ChartName,
    FormInputCheckbox,
    FormSelectFileVersion,
    Duration,
    FormInput,
    FormInputFile,
    FormInputNumber,
    FormInputGroup,
    FormTextArea,
    FormSelectGender,
    FormSelectIntensity,
    FormSelectActivity,
    FormSelectGarment,
    ListOperations
  },

  props: {
    session: {
      type: [ String ],
      default: null
    }
  },

  data() {
    return {
      submitting: false,
      name: null,
      description: null,
      activity: null,
      intensity: null,
      age: null,
      height: null,
      weight: null,
      gender: null,
      observations: null,
      garment: null,
      file: {
        file: null,
        version: '100'
      },
      filters: {
        outliers: {
          enabled: true,
          advanced: false,
          coefficient: 2000,
          tolerance: 3
        }
      }
    }
  },

  validations() {

    const mapping = {
      name: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(255)
      },
      description: {
        maxLength: maxLength(65534)
      },
      activity: {
        required
      },
      intensity: {
        required
      },
      age: {
        required,
        between: between(1, 120)
      },
      height: {
        required,
        between: between(1, 300)
      },
      weight: {
        required,
        between: between(1, 250)
      },
      gender: {
        required
      },
      observations: {
        maxLength: maxLength(65534)
      },
    };

    if(this.session) {
      mapping['garment'] = {
        required
      };
    }

    if(!this.session) {
      mapping['garment'] = {};

      mapping['file'] = {
        file: {
          required
        },
        version: {
          required
        },
      };

      mapping['filters'] = {
        outliers: {
          enabled: {},
          coefficient: {
            required
          },
          tolerance: {
            required,
            between: between(1, 100)
          }
        }
      };
    }

    return mapping;
  },

  methods: {
    load() {
      if(!this.session) {
        return;
      }

      this.lock();
      http
        .get(`/sessions/${this.session}`)
        .then((response) => _.assign(this, response.data))
        .finally(() => this.unlock())
    },

    lock() {
      this.submitting = true;
    },

    unlock() {
      this.submitting = false;
    },

    toggleOutliersAdvancedConfig() {
      this.filters.outliers.advanced = !this.filters.outliers.advanced;
    },

    onSubmit() {
      this.$v.$touch();

      if (this.$v.$anyError) {
        console.info('There is some issue in form. Please review it!', this.$v);
        return;
      }

      const data = new FormData();
      data.append('name', this.name);
      data.append('activity', this.activity.uuid);
      data.append('intensity', this.intensity.value);
      data.append('age', this.age);
      data.append('height', this.height);
      data.append('weight', this.weight);
      data.append('gender', this.gender.value);
      data.append('description', this.description ? this.description : '');
      data.append('observations', this.observations ? this.observations : '');

      this.lock();

      let promise;
      if(this.session) {
        // FIXME should be PUT, there are some issue with FOSRestBundle and multipart form in
        promise = http.post(`/sessions/${this.session}`, data);
      } else {
        // Add editable inputs
        data.append('garment', this.garment.uuid);
        data.append('file[file]', this.file.file);
        data.append('file[version]', this.file.version.value);
        data.append('outliers[coefficient]', this.filters.outliers.coefficient);
        data.append('outliers[tolerance]', this.filters.outliers.tolerance);

        promise = http.post('/sessions', data);
      }

      promise
        .then(() => this.$router.push({ name: 'sessions_list' }))
        .finally(() => this.unlock())
      ;
    }
  },

  mounted() {
    this.load();
  }
}
</script>

<i18n>
{
  "es": {
    "edit": {
      "warning": "No se puede cambiar la prenda, dado que el fichero de sesión va asociado a la misma."
    },
    "form": {
      "activity": {
        "title": "Información sobre la actividad",
        "name": {
          "label": "Nombre de la sesión",
          "placeholder": "Escribe un nombre corto y descriptivo"
        },
        "description": {
          "label": "Descripción",
          "placeholder": "Escribe algo que describa la sesión (opcional)"
        },
        "type": {
          "label": "Tipo de actividad",
          "placeholder": "Selecciona una actividad"
        },
        "intensity": {
          "label": "Intensidad",
          "placeholder": "Selecciona una intensidad"
        }
      },
      "subject": {
        "title": "Información del sujeto",
        "age": {
          "label": "Edad",
          "placeholder": "0"
        },
        "height": {
          "label": "Altura",
          "placeholder": "180"
        },
        "weight": {
          "label": "Peso",
          "placeholder": "71.5"
        },
        "gender": {
          "label": "Sexo",
          "placeholder": "Selecciona"
        },
        "observations": {
          "label": "Observaciones",
          "placeholder": "Observaciones sobre el sujeto (opcional)"
        }
      },
      "garment": {
        "title": "Prenda usada",
        "garment": {
          "label": "Prenda",
          "placeholder": "Selecciona la prenda"
        },
        "details": {
          "sensors": "Sensores",
          "charts": "Gráficos asociados",
          "selectOne": "(Seleccione una prenda para más detalles)",
          "noImage": " (No hay una imagen disponible)"
        }
      },
      "file": {
        "title": "Fichero",
        "label": "Buscar fichero de telemetría",
        "search": "Buscar"
      },
      "fileVersion": {
        "label": "Versión del fichero",
        "placeholder": "Selecciona una versión",
        "help": "La versión del fichero determina cómo está construido y, por lo tanto, cómo debe procesarse"
      },
      "coefficient": {
        "label": "Coeficiente"
      },
      "tolerance": {
        "label": "Tolerancia",
        "help": "Cuánto más alta, menos valores erróneos son descartados"
      },
      "submit": {
        "create": {
          "label": "Enviar a analizar"
        },
        "edit": {
          "label": "Guardar"
        }
      }
    },
    "outliers": {
      "tab": {
        "description": "La reducción de ruido elimina valores fuera de lugar debido a interferencias en el proceso de captura. Estos valores se usarán para generar el informe inicial y <b>no es necesario modificarlos</b>, salvo que así lo desee. Más tarde puede ajustar si lo desea estos parámeteros para conseguir resultados más óptimos.",
        "name": "Reducción de ruido"
      },
      "enable": "Activar reducción de ruido y tolerancia a errores",
      "less": "Ocultar opciones avanzadas",
      "more": "Mostrar opciones avanzadas"
    },
    "table": {
      "header": {
        "name": "Nombre",
        "description": "Description",
        "duration": "Duración",
        "createdAt": "Fecha creación"
      }
    }
  }
}
</i18n>
